/*
    Navigation
*/
.navigation {
    &-desktop,
    &-mobile,
    &-mobile.has-active {
        .item-name-wrapper {
            .item-name {
                font-family: $font-brandon-bold;
                margin: 0;
            }

            .item-name-maori {
                font-family: $font-primary;
                margin: 0;
            }
        }

        .nav-item,
        .sub-item {
            border: none;
            text-align: left;
            text-transform: none;
            font-size: rem(16);
            line-height: 1.6;
        }

        .tiles {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;
            flex-wrap: wrap;
        }
    }

    &-desktop {
        display: none;
        height: 100%;

        @media (--from-normal-screen-w) {
            display: flex;
        }

        .nav-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0 rem(26);
            border-top: rem(3) solid transparent;
            border-right: rem(1) solid $color-quaternary-shade-1;
            cursor: pointer;
            color: $color-secondary;
            transition: all 0.2s;

            @media (--from-normal-screen-w) {
                padding: 0 rem(15);
            }

            @media (--from-large-screen-w) {
                padding: 0 rem(26);
            }

            &:first-child {
                border-left: rem(1) solid $color-quaternary-shade-1;
            }

            &.is-active,
            &:hover {
                border-top-color: $color-secondary;
                color: $color-secondary;
            }
        }

        .sub-nav-container {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            background-color: $color-tertiary;
            border: rem(1) solid $color-quaternary-shade-1;
            animation: subNavIn 0.35s ease-in-out;
            color: $color-secondary;

            .inner {
                display: flex;
                justify-content: center;
                align-items: stretch;
            }

            .sub-item-container,
            .featured-container {
                padding: rem(40);
            }

            .featured-container {
                flex-basis: 60%;
                border-right: 1px solid $color-quaternary-shade-2;
            }


            .sub-item-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: stretch;
                flex: 1 1 40%;
            }

            .sub-item {
                display: block;
                color: $color-secondary;
                text-transform: none;
                padding: rem(20);
                padding-left: rem(50);
                margin-bottom: rem(20);
                width: 30%;
                transition: all 0.2s;
                position: relative;
                border-radius: rem(3);

                .name {
                    margin-bottom: rem(10);
                }

                .description {
                    font-size: rem(14);
                    color: $color-quaternary-shade-3;
                    margin-bottom: 0;
                }

                .icon {
                    position: absolute;
                    top: rem(15);
                    left: rem(10);
                    width: rem(25);
                }

                &:hover {
                    color: $color-primary;
                    text-decoration: none;
                    background-color: $color-quaternary;
                }

                &.is-active {
                    color: $color-primary;
                }
            }

            .title {
                color: $color-quaternary-shade-3;
                text-transform: uppercase;
                margin-bottom: rem(30);
            }

            // Featured sub nav
            &.has-featured {

                .sub-item-container {
                    background-color: $color-quaternary;
                    flex-direction: column;
                    padding-bottom: rem(40);

                    &:only-child {
                        align-items: center;

                        .sub-item {
                            width: fit-content;
                        }
                    }
                }

                .featured-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: stretch;
                }
            

                .sub-item {
                    padding: 0;
                    width: 100%;
                    margin-bottom: rem(10);

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                hr {
                    margin: rem(20) rem(-40) rem(40) rem(-40);
                    border-color: $color-quaternary-shade-2;
                }
            }
        }

        .nav-item,
        .sub-item {
            &:hover {
                text-decoration: none;
            }
        }
    }

    // Mobile view
    &-mobile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;;
        align-items: center;
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: $layer-full-screen;
        background-color: $color-tertiary;
        top: 0;
        left: 0;
        overflow: scroll;

        @media (--from-normal-screen-w) {
            display: none;
        }

        & > .item-name-wrapper {
            width: 100%;
            padding: rem(10) rem(20);
            border-top: rem(5) solid $color-secondary;
            color: $color-secondary;

            @media (--from-medium-screen-w) {
                padding: rem(10) rem(30);
            }
        }

        .nav-item,
        .sub-item {
            display: block;
            width: 100%;
            text-align: left;
            border: none;
            padding: rem(20) rem(20) rem(10);
            color: $color-secondary;
            font-family: $font-brandon-bold;
            color: $color-secondary;

            p:last-of-type {
                margin-bottom: 0;
            }
        }

        .nav-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;


            &:not(:last-child) {
                border-bottom: 1px solid $color-quaternary-shade-2;
            }

            @media (--from-medium-screen-w) {
                padding: rem(30);
            }

            &:hover {
                text-decoration: none;
            }

            .item-name-wrapper {
                flex: 1;
                text-transform: none;
            }

            .icon {
                transform: rotate(-90deg);
                width: rem(20);
                height: rem(20);
            }
        }

        .sub-nav-container {
            width: 100%;
            border-top: rem(1) solid $color-quaternary-shade-2;

            hr {
                border-color: $color-quaternary-shade-2;
                margin-top: rem(10);
            }
        }

        .sub-item {
            padding-left: rem(40);
            position: relative;

            &:hover {
                text-decoration: none;
            }

            .description {
                font-family: $font-primary;
                font-size: rem(14);
                color: $color-quaternary-shade-3;
                margin-bottom: 0;
            }

            .icon {
                position: absolute;
                top: rem(20);
                left: rem(10);
                width: rem(20);
                height: rem(20);
            }

            .name {
                margin-bottom: rem(5);
            }
        }

        .tiles {
            width: 100%;
            padding: rem(20);
            border-top: rem(1) solid $color-quaternary-shade-2;

            @media (--from-medium-screen-w) {
                padding: rem(20) rem(30);
            }
        }

        .button.action-button {
            color: $color-secondary;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding: rem(20);
            
            .icon {
                width: rem(20);
                height: rem(20);
            }

            .icon.left-arrow {
                transform: rotate(90deg);
            }
        }
    }
}

