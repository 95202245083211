/*
    Blockquote
*/
blockquote,
q {
    display: block;
    padding-top: rem(20);
    margin-bottom: rem(30);

    font-family: $font-brandon-black-italic;
    font-size: rem(20);
    line-height: 1.2;
    text-align: center;

    quotes: '\201C' '\201D';

    @media(--from-small-screen-w) {
        font-size: rem(28);
    }

    @media(--from-normal-screen-w) {
        font-size: rem(34);
    }


    // Width
    &,
    & + cite {
        max-width: rem(640);
        margin-right: auto;
        margin-left: auto;
    }


    // Cite
    & + cite {
        display: block;

        margin-top: rem(-15);
        margin-bottom: rem(20);

        font-size: rem(14);
        font-style: normal;
        text-align: center;

        @media(--from-normal-screen-w) {
            font-size: rem(18);
        }

        &:before {
            content: '- ';
        }

        &:after {
            display: block;

            width: rem(50);
            margin-top: 20px;
            margin-right: auto;
            margin-left: auto;

            border-top: 4px solid;

            content: '';
        }
    }
}

blockquote {
    p:first-child:before {
        content: open-quote;
    }

    p:last-child:after {
        content: close-quote;
    }
}

q {
    &:before {
        content: open-quote;
    }

    &:after {
        content: close-quote;
    }
}
 