/*
    Performances View Mode Controls
*/
.performances-view-mode-controls {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: stretch;
    height: 100%;
    padding-right: rem($constraint-gutter-screen-small);
    padding-left: rem($constraint-gutter-screen-small);

    @media(--to-large-screen-w) {
        display: none;
    }

    // Elements
    .performances-view-mode-control {
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(55);

        transition: color 0.2s;

        // States
        &:hover,
        &.is-active {
            color: $color-primary;
        }

        // Elements
        .icon {
            width: rem(30);
            height: rem(30);
        }
    }
}
