/*
    Newsletter strip
*/
.newletter-strip {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $color-tertiary;
    box-shadow: 0 -4px 4px 0 $color-quaternary-shade-3-020;
    padding: rem(15);
    transition: transform 0.35s ease-in-out;
    z-index: 1;

    @media (--from-normal-screen-w) {
        padding: rem(5) rem(20);
    }

    &.is-hiding {
        transform: translateY(calc(100% + rem(60)));

        @media (--from-normal-screen-w) {
            transform: translateY(100%);
        }
    }

    .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (--from-normal-screen-w) {
            height: rem(80);
            padding-left: rem(30);
            background: url($folder-icon/src/mail-black-bg.svg) no-repeat left center;
            background-size: 20px auto;
        }

        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            @media (--from-tiny-screen-w) {
                width: unset;
                justify-content: flex-start;
            }
        }

        p {
            margin: 0 rem(10) 0 0;
            font-size: rem(16);
            line-height: 1.2;
            width: rem(240);

            @media (--from-tiny-screen-w) {
                width: unset;
            }

            @media (--from-normal-screen-w) {
                line-height: 1.4;
            }
        }

        .strip-toggle {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            transform: translateY(-100%);
            flex-shrink: 0;

            @media (--from-normal-screen-w) {
                position: static;
                transform: none;
            }

            .logo-container {
                height: rem(60);
                max-width: 100%;
                max-height: 100%;
                transition: opacity 0.35s ease-in-out;
                opacity: 0;
                background-color: $color-white;

                @media (--from-normal-screen-w) {
                    margin-right: rem(20);
                }

                &.is-active {
                    opacity: 1;
                }
            }

            .logo {
                height: 100%;
                object-fit: contain;
            }
        }

        button.sign-up {
            flex-shrink: 0;
        }

        button.close {
            background-color: $color-quaternary-shade-3-090;
            border-radius: 0;
            color: $color-tertiary;

            @media (--from-normal-screen-w) {
                background-color: $color-tertiary;
                color: $color-secondary;
            }

        }
    }
}