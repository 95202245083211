/*
    Video block
*/
.video-block {
    position: relative;
    padding-top: rem(45);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    text-align: center;

    // Children
    .inner {
        position: relative; // Positions above background
        width: 100%;
        margin-right: 0; // Remove margin causing issues in flexbox alignment
        margin-left: 0;
    }

    .meta {
        margin-bottom: rem(25);
    }

    .player {
        width: 100%;

        @media(--to-medium-screen-w) {
            min-height: 70vw;
        }

        @media(--from-medium-screen-w) {
            height: rem(500);
        }

        @media(--from-normal-screen-w) {
            height: rem(550);
        }
    }


    // Text
    .credit {
        margin-bottom: 0;

        font-family: $font-brandon-regular-italic;
    }
}
