/*
    News container in News page
*/
.news-container {
    background-color: $color-tertiary;

    .category-select {
        display: flex;
        justify-content: center;
        padding-top: rem(20);

        @media (--from-normal-screen-w) {
            display: none;
        }

        .select-wrapper {
            width: 100%;
            height: rem(40);
            border: rem(2) solid $color-secondary;
            border-radius: rem(3);

            select {
                color: $color-secondary;
                background-color: $color-tertiary;
            }
        }
    }

    .tabs-wrapper .tabs-tab-list {
        display: none;

        @media (--from-normal-screen-w) {
            display: flex;
        }
    }

    .tiles-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        padding-top: rem(20);
    }
}