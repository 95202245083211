/*
    Slider tile
*/
.tile-slider {
    @extend .tile-verticle;
    display: block;
    overflow: hidden;

    &.has-presenter {
        padding-top: 0;
    }

    .presenter {
        height: auto;
        opacity: 0.8;
        transform: translateY(-100%);
    }

    .tile-thumbnail {
        width: 100%;
        height: 0;
        padding-top: calc( 400 / 640 * 100%);
        overflow: hidden;

        .image {
            position: absolute;
            top: 0;
            height: 100%;
            transition: transform .25s ease-in-out;

            &.desktop {
                display: block;
            }

            &.mobile {
                display: none;
            }
        }
    }

    .information {
        width: 100%;
        position: relative;
        display: block;
        padding: rem(18) rem(10);
        height: rem(140);

        .further {
            display: none;

            @media (--from-medium-screen-w) {
                display: block;
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                padding: rem(18) rem(10);
                border-top: rem(1) solid $color-quaternary-shade-1;
            }
        }

        .sub-information {
            position: absolute;
            left: 0;
            bottom: rem(10);
            padding: 0 rem(10);
        }
        
        .further .price,
        .price,
        .location {
            padding-left: 0;
            background: none;
        }

        .genre {
            position: static;
        }
    }
}