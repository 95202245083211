/*
    Image gallery
*/
.image-gallery-block {
    position: relative;

    &:hover {
        .slick-slider .slick-arrow {
            transition: opacity 0.2s ease-in;
            opacity: 1;
        }
    }

    .image-wrapper{
        margin-right: rem(10);

        img {
            height: rem(300);
            margin: auto;

            @media (--from-normal-screen-w) {
                height: rem(400);
            }
        }
    }

    // Lightbox
    .lightbox {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 2;
        display: flex;
        align-items: center;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $color-secondary;
            opacity: 0.95;
        }

        .image-wrapper {
            margin-right: 0;

            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;

                @media (--from-medium-screen-w) {
                    max-width: 80vw;
                    max-height: 80vh;
                }
            }
        }

        .slick-slider {
            .slick-arrow {
                opacity: 1;
            }
        }

        .creditline {
            text-align: center;
            color: $color-tertiary;
            font-size: rem(16);
            margin-top: rem(20);
            
            p {
                font-size: rem(16);
            }
        }
    }
}