/*
    Header
*/
.header-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $layer-header;
    background-color: $color-tertiary;

    .header-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: rem($header-height-desktop);
        max-height: rem($header-height-desktop);
        position: relative;
        z-index: $layer-header;
    }

    .logo {
        display: flex;
        position: absolute;
        padding: 0;
        background: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: rem(200);
        max-height: 100%;
        color: $color-secondary;

        @media (--from-normal-screen-w) {
            position: initial;
            transform: translate(0, 0);
            width: rem(230);
            padding: rem(10) 0;
        }

        @media (--from-large-screen-w) {
            width: rem(280);
        }

        &:hover {
            text-decoration: none;
        }
    
        .image {
            width: rem(100);
            max-height: 100%;
            display: none;

            @media (--from-normal-screen-w) {
                display: block;
                width: rem(50);
            }

            @media (--from-large-screen-w) {
                width: rem(70);
            }

            .st0,
            .st1,
            .st2 {
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
                animation: dashIn 1s ease-out forwards;
            }
        }

        svg {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }

        .text {
            text-decoration: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: rem(5);

            @media (--from-normal-screen-w) {
                align-items: flex-start;
            }

            svg {
                width: rem(160);
            }
        }

        .credit-line {
            font-size: rem(10);
            white-space: nowrap;
            overflow: hidden;
            animation: textIn 1s forwards;
            
            @media (--from-large-screen-w) {
                font-size: rem(12);
            }
        }
    }

    .actions {
        display: flex;

        &.hide-on-desktop {
            @media (--from-normal-screen-w) {
                display: none;
            }
        }

        & > *:not(:last-child) {
            @media (--from-small-screen-w) {
                margin-right: rem(10);
            }
        }

        // Actions button
        & > button.mobile-menu {
            display: flex;
            border: none;

            @media (--from-normal-screen-w) {
                display: none;
            }
        }

        & > button.find-event {
            display: none;

            @media (--from-medium-screen-w) {
                display: block;
            }
            
            @media (--from-normal-screen-w) {
                display: none;
            }
        }

        & > button.notification-button {
            position: relative;
            display: flex;
            
            @media (--from-small-screen-w) {
                display: none;
            }

            @media (--from-normal-screen-w) {
                display: flex;
            }

            &.is-active::before {
                content: '';
                width: rem(8);
                height: rem(8);
                background-color: $color-cancellation;
                position: absolute;
                top: rem(2);
                right: rem(5);
                border-radius: 50%;
            }

            &.hide-on-tablet {
                @media (--from-medium-screen-w) {
                    display: none;
                }

                @media (--from-normal-screen-w) {
                    display: flex;
                }
            }

            &.hide-on-mobile {
                display: none;

                @media (--from-small-screen-w) {
                    display: flex;
                }
            }

            &.is-hidden {
                display: none;
            }
        }

        button.mobile-menu,
        button.notification-button,
        button.search {
            @media (--to-small-screen-w) {
                border: none;
            }
        }
    }

    // Find aa event banner
    .button.find-event-banner {
        display: block;
        width: 100%;
        height: rem(50);
        max-height: none;
        text-align: left;
        position: relative;
        border-radius: 0;
        border: 0;
        border-bottom: rem(1) solid $color-tertiary-020;;
        color: $color-secondary;
        background-color: $color-quaternary;

        @media (--from-medium-screen-w) {
            display: none;
        }

        .label-icon {
            position: absolute;
            top: 50%;
            right: rem(20);
            transform: translateY(-50%) rotate(180deg);
        }
    }

    hr {
        border-color: $color-tertiary-020;
        margin: 0;
    }

    // State
    &.is-scrolling {

        .header-inner {
            height: rem($header-height-desktop-small);
            max-height: rem($header-height-desktop-small);
            transition: all 0.1s ease-in 1s;
        }
        
        .logo {
            .image {
                width: 0;
                transition: width 0.1s ease-in 1s;

                .st0,
                .st1,
                .st2 {
                    stroke-dasharray: 1000;
                    stroke-dashoffset: 1000;
                    animation: dashOut 1s ease-out forwards;
                }
            }

            .text svg {
                width: rem(200);
                transition: width 0.1s linear 1s;
            }

            .credit-line {
                animation: textOut 1s forwards;
            }
        }
    }

    &.theme-dark {
        background-color: $color-secondary-050;

        .header-inner {
            color: $color-tertiary;
            border-color: $color-tertiary-020;
        }

        .logo {
            color: $color-tertiary;
        }

        .navigation-desktop .nav-item {
            color: $color-tertiary;
            border-right-color: $color-tertiary-020;
            border-left-color: $color-tertiary-020;

            &.is-active,
            &:hover {
                border-top-color: $color-tertiary;
            }
        }

        .show-finder-wrapper {
            background-color: transparent;

            button.find-event-banner {
                color: $color-tertiary;
                background-color: transparent;
            }
        }

        .show-finder:not(.show-finder-dialog) {
            .label {
                color: $color-tertiary;
            }

            button.secondary {
                color: $color-tertiary;
                border-color: $color-tertiary;

                &:hover {
                    color: $color-secondary;
                    background-color: $color-tertiary;
                }
            }
        }
    }
}