/*
    Performances Calendar Month Performance Book Button
*/
.performance-timetable .button.performances-calendar-month-performance-book-button {
    position: relative;
    width: 100%;
    padding: rem(4) rem(10);

    border: 1px solid transparent;
    
    // Types
    &:not(.is-available) {
        text-transform: none;
        
        cursor: not-allowed;
    }

    // Elements
    .icon {
        position: absolute;
        top: 50%;
        left: rem(6);

        width: rem(20);
        height: rem(20);

        transform: translateY(-54%);
    }
}
