/*
    Treble content block
*/
.treble-block {

    .item-container {
        display: flex;
        flex-direction: column;

        @media (--from-normal-screen-w) {
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;
            justify-content: space-between;
            margin: 0 rem(-10);
        }

        & > * {
            flex: 1;
            margin: 0 0 rem(10);

            @media (--from-normal-screen-w) {
                margin: 0 rem(10);
            }
        }
    }

    .item {
        position: relative;
        z-index: 0;
        padding: rem(30) rem(20) rem(70);
        cursor: pointer;
        color: $color-tertiary;
        border-top: rem(5) solid $color-primary;
        min-height: rem(200);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        @media (--from-normal-screen-w) {
            min-height: rem(300);
        }

        &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $color-secondary-070;
            z-index: -1;
            transition: background-color 0.2s;
        }

        &:hover {
            text-decoration: none;

            &::before {
                background-color: $color-secondary-080;
            }

            button.cta {
                color: $color-primary;
            }
            
        }

        button.cta {
            position: absolute;
            left: rem(20);
            bottom: rem(30);
            border: none;
            color: $color-tertiary;
            text-transform: none;
            transition: color 0.2s;
            padding-left: 0;
        }
    }
}