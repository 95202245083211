/*
    Performances Calendar Month
*/
.performances-calendar-month {
    margin-top: rem(15);
    margin-bottom: rem(15);

    background-color: $color-tertiary;
    box-shadow: inset 0 0 0 rem(1) $color-quaternary-shade-1;

    // Elements
    &-key {
        display: flex;
        align-items: center;
        padding-top: rem(10);
        padding-bottom: rem(10);
        padding-left: rem(20);

        &-label {
            padding-right: rem(15);
        }

        &-faux-buttons {
            flex-grow: 1;
        }

        &-faux-button-wrapper {
            position: relative;

            display: inline-block;
            width: rem(120);
            margin-right: rem(10);

            // Pseudo
            &::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                
                content: '';
            }
        }

        &-accessibility {
            display: flex;

            &-option {
                display: flex;
                align-items: center;
                margin-right: rem(30);

                .icon {
                    width: rem(20);
                    height: rem(20);
                    margin-right: rem(10);

                    &.larger {
                        width: rem(24);
                        height: rem(24);

                        margin-right: rem(5);
                    }
                }

                &-label {
                    font-size: rem(14);

                    transform: translateY(rem(1));
                }
            }
        }
    }

    &-head {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

        padding-top: rem(1);
        padding-left: rem(1);
        margin-right: rem(-1);

        // Elements
        &-day {
            padding-top: rem(4);
            padding-right: rem(15);
            padding-bottom: rem(4);
            padding-left: rem(15);

            color: $color-quaternary-shade-3;
            font-size: rem(18);
            text-align: center;
            text-transform: uppercase;
        }
    }

    &-body {
        display: -ms-grid;
        display: grid;
        grid-auto-rows: 1fr;
        -ms-grid-columns:  1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

        padding-top: rem(1);
        padding-left: rem(1);
        margin-top: rem(-1);
        margin-right: rem(-1);

        user-select: none;

        // Elements
        &-day {
            position: relative;
            min-height: rem(179);
            
            padding-top: rem(3);
            padding-right: rem(15);
            padding-bottom: rem(12);
            padding-left: rem(15);

            transition: box-shadow 100ms ease-in-out;

            // Types
            &:not(.is-current-month) {
                opacity: 0;
            }

            &.is-past > * {
                opacity: 0.5;
            }

            &.has-performances:not(.is-past):hover {
                position: relative;

                box-shadow: inset 0 0 0 rem(2) $color-primary;
            }

            // Elements
            &-date {
                max-width: rem(20);
                margin-bottom: rem(6);
                margin-left: rem(-4);
                
                font-family: $font-brandon-bold;
                font-size: rem(24);
                text-align: center;
            }

            &-accessibility {
                position: absolute;
                top: rem(7);
                right: rem(7);
                display: flex;
                align-items: center;
                height: rem(24);

                .icon {
                    width: rem(20);
                    height: rem(20);
                    margin-right: rem(5);

                    &.larger {
                        width: rem(24);
                        height: rem(24);
                    }
                }
            }
        }
    }

    &-head-day,
    &-body-day {
        min-width: 0;
        margin-top: rem(-1);
        margin-left: rem(-1);

        box-shadow: inset 0 0 0 rem(1) $color-quaternary-shade-1;

        // Types
        &:nth-child(n+1):nth-child(-n+7) {
            -ms-grid-row: 1;
            grid-row: 1;
        }

        &:nth-child(n+8):nth-child(-n+14) {
            -ms-grid-row: 2;
            grid-row: 2;
        }

        &:nth-child(n+15):nth-child(-n+21) {
            -ms-grid-row: 3;
            grid-row: 3;
        }

        &:nth-child(n+22):nth-child(-n+28) {
            -ms-grid-row: 4;
            grid-row: 4;
        }

        &:nth-child(n+29):nth-child(-n+35) {
            -ms-grid-row: 5;
            grid-row: 5;
        }

        &:nth-child(n+36):nth-child(-n+42) {
            -ms-grid-row: 6;
            grid-row: 6;
        }

        &:nth-of-type(7n+1) {
            -ms-grid-column: 1;
            grid-column: 1;
        }

        &:nth-of-type(7n+2) {
            -ms-grid-column: 2;
            grid-column: 2;
        }

        &:nth-of-type(7n+3) {
            -ms-grid-column: 3;
            grid-column: 3;
        }

        &:nth-of-type(7n+4) {
            -ms-grid-column: 4;
            grid-column: 4;
        }

        &:nth-of-type(7n+5) {
            -ms-grid-column: 5;
            grid-column: 5;
        }

        &:nth-of-type(7n+6) {
            -ms-grid-column: 6;
            grid-column: 6;
        }

        &:nth-of-type(7n+7) {
            -ms-grid-column: 7;
            grid-column: 7;
        }
    }

    &-performance {
        margin-bottom: rem(5);

        text-align: center;

        &-price {
            padding-top: rem(4);

            font-size: rem(14);
        }
    }
}
