/*
    Virtual tour
*/
.virtual-tour {
    // Iframe size too big issue on mobile
    width: 100%;
    .player {
        height: 600px;
    }
    @media(--to-small-screen-w) {
        .player {
            height: 400px;
        }
        width: 90%;
    }
}