/*
    Layout
*/
.constrain-width {
    max-width: rem($constraint-width-normal);
    padding-right: rem($constraint-gutter-screen-small);
    padding-left: rem($constraint-gutter-screen-small);
    margin-right: auto;
    margin-left: auto;

    @media(--from-small-screen-w) {
        padding-right: rem($constraint-gutter-screen-medium);
        padding-left: rem($constraint-gutter-screen-medium);
    }


    // Sizes
    &.tiny {
        max-width: rem($constraint-width-tiny);
    }

    &.small {
        max-width: rem($constraint-width-small);
    }

    &.medium {
        max-width: rem($constraint-width-medium);
    }

    &.large {
        max-width: rem($constraint-width-large);
    }

    &.no-padding {
        padding-left: 0;
        padding-right: 0;
    }
}
