/*
    Button
*/
button,
.button,
a.button {
    font-size: rem(14);
    font-family: $font-brandon-medium;
    padding: rem(10);
    border: rem(1) solid currentColor;
    border-radius: rem(3);
    text-transform: uppercase;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    background: transparent;
    text-align: center;

    &:focus {
        outline: none;
    }

    @media (--from-normal-screen-w) {
        padding: rem(10) rem(20);
    }

    &.primary {
        color: $color-primary;
        background-color: transparent;

        &:hover {
            color: $color-tertiary;
            border-color: $color-primary;
            background-color: $color-primary;
        }

        &.inverse {
            color: $color-tertiary;
            border-color: $color-primary;
            background-color: $color-primary;
    
            &:hover {
                background-color: $color-primary-tint-1;
                border-color: $color-primary-tint-1;
            }
        }
    }

    &:disabled {
        cursor: not-allowed;
    }

    &.secondary {
        color: $color-secondary;
        background-color: transparent;

        &:hover {
            color: $color-tertiary;
            border-color: $color-secondary;
            background-color: $color-secondary;
        }

        &.inverse {
            color: $color-tertiary;
            border-color: $color-secondary;
            background-color: $color-secondary;
    
            &:hover {
                color: $color-secondary;
                background-color: $color-tertiary;
            }
        }

        // Theme dark
        &.theme-dark {
            color: $color-tertiary;
            border-color: $color-tertiary;

            &:hover {
                color: $color-secondary;
                background-color: $color-tertiary;
            }
        }
    }

    &.available {
        color: $color-tertiary;
        background-color: $color-theme-green;

        &:hover {
            background-color: $color-theme-green-shade-1;
        }

        &.inverse {
            color: $color-theme-green;

            background-color: $color-tertiary;
            border-color: currentColor;
    
            &:hover {
                color: $color-tertiary;
    
                background-color: $color-theme-green-shade-1;
                border-color: $color-theme-green-shade-1;
            }
        }
    }

    &.inverse {
        color: currentColor;
        border-color: currentColor;
        background-color: transparent;

        &.light:hover {
            color: $color-secondary;
            background-color: $color-tertiary;
        }

        &.dark:hover {
            color: $color-tertiary;
            border-color: $color-secondary;
            background-color: $color-secondary;
        }
    }

    &.small {
        padding: rem(10);
    }

    .icon {
        width: rem(15);
        height: rem(15);

        &:not(:last-child) {
            margin-right: rem(5);
        }

        &.large {
            width: rem(20);
            height: rem(20);
            margin: 0;
        }
    }

    &.no-border {
        border: none;
    }

    .text {
        display: none;

        @media (--from-small-screen-w) {
            display: inline-block;
        }
    }

    a&:hover {
        text-decoration: none;
    }
}