/*
    Pagination
*/
.pagination {
    .actions {
        text-align: center;
        margin: rem(20) 0 0;

        button.button {
            margin: auto;
        }
    }

    .amount {
        margin-top: rem(20);
        margin-bottom: rem(10);
        text-align: center;
        font-size: rem(14);
    }
}