/*
    Hero carousel
*/
.hero-carousel {
    position: relative;
    margin-top: rem(-$navigation-height-mobile);

    @media (--from-medium-screen-w) {
        margin-top: rem(-$navigation-height-tablet);
    }

    @media (--from-normal-screen-w) {
        margin-top: rem(-$navigation-height-desktop);
    }

    &-container {
        width: 100%;
        position: relative;
        padding-top: rem($navigation-height-mobile);
        z-index: 0;
        overflow: hidden;

        @media (--from-medium-screen-w) {
            padding-top: calc(rem($navigation-height-tablet) + rem(50));
        }
    
        @media (--from-normal-screen-w) {
            padding-top: calc(rem($navigation-height-desktop) + rem(50));
        }
    }

    &-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        .image,
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .image {
            object-fit: cover;
            opacity: 0;
            filter: blur(5px);
            animation: imgReveal 0.5s;
            animation-fill-mode: forwards;
        }
    }

    .hero {
        background-color: transparent;

        &-wrapper {
            display: block;
            width: 100%;

            @media (--from-medium-screen-w) {
                padding: 0 rem(60);
            }

            @media (--from-normal-screen-w) {
                padding: 0 rem(120);
            }

            &:hover {
                text-decoration: none;
                
                .hero-image image {
                    transform: scale(1.02);
                }  
            }
        }

        &-media-container {
            display: block;
            padding-top: calc($homepage-carousel-image-height-mobile);
            overflow: hidden;
            position: relative;

            @media (--from-medium-screen-w) {
                padding-top: calc($homepage-carousel-image-height-desktop);
            }

            @media (--from-normal-screen-w) {
                margin-bottom: rem(20);
            }

            .video-player,
            .image {
                display: none;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                position: absolute;
                top: 0;

                @media (--from-medium-screen-w) {
                    display: block;
                }

                &.mobile {
                    display: block;

                    @media (--from-medium-screen-w) {
                        display: none;
                    }
                }
            }

            .video-player {
                z-index: 1;
                display: none;
                transform: translateY(-12.5%);

                @media (--from-medium-screen-w) {
                    display: block;
                }
            }
        }

        &-banner {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            min-height: rem(120);
            padding: rem(60) rem(20) rem(20);

            @media (--from-medium-screen-w) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                height: rem(90);
                min-height: unset;
                padding: rem(20) 0;
            }
 
            .banner-info {
                margin-right: rem(20);
                margin-bottom: rem(20);
                overflow: hidden;

                @media (--from-medium-screen-w) {
                    margin-bottom: 0;
                }

                & > .title,
                & > .sub-title {
                    margin-bottom: 0;
                    
                    @media (--from-medium-screen-w) {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }

            & > a.button {
                color: inherit;
                flex-shrink: 0;

                &:hover {
                    border-color: $color-tertiary;
                    color: $color-secondary;
                    background-color: $color-tertiary;
                }
            }
        }
    }

    .paging-container {
        width: 100%;
        display: flex;
        position: absolute;
        top: 0;
        margin-top: calc(rem($navigation-height-mobile) + $homepage-carousel-image-height-mobile + rem(10));
        padding: 0 rem(10);
        z-index: 1;

        @media (--from-medium-screen-w) {
            top: 50%;
            left: 20px;
            width: auto;
            transform: translate(0, -50%);
            margin-top: 0;
            padding: 0;
        }


        @media (--from-x-large-screen-w) {
            left: 50%;
            transform: translate(-660px, -50%);
        }
    }

    .paging {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @media (--from-medium-screen-w) {
            width: unset;
            flex-direction: column;
            justify-content: center;
        }

        .slick-arrow,
        .dot {
            padding: 0;
            border: none;
            color: currentColor;
        }

        .slick-arrow {
            &__prev {
                transform: rotate(90deg);
                margin-right: rem(10);

                @media (--from-medium-screen-w) {
                    transform: rotate(180deg);
                    margin-right: 0;
                    margin-bottom: rem(10);
                }
            }

            &__next {
                transform: rotate(-90deg);
                margin-left: rem(10);

                @media (--from-medium-screen-w) {
                    transform: none;
                    margin-left: 0;
                    margin-top: rem(10);
                }
            }

            svg {
                width: rem(20);
                height: rem(20);
            }
        }

        .dot-container {
            position: relative;

            .tooltip {
                font-family: $font-brandon-medium;
                display: none;
                position: absolute;
                top: 0;
                left: rem(50);
                width: rem(260);


                p {
                    margin: 0;                
                    padding: rem(10);
                    background: $color-tertiary;
                    color: $color-secondary;
                    border-radius: rem(3);
                    width: fit-content;
                }
            }

            &:hover {
                .tooltip {
                    @media (--from-medium-screen-w) {
                        display: block;
                    }
                }
            }
        }

        .dot {
            margin: rem(5) 0;
            padding: rem(5);
            border-radius: 100%;
            opacity: 0.3;

            &:hover {
                background-color: currentColor;
            }

            svg {
                width: rem(7);
                height: rem(7);
            }

            //  Timer
            &.is-active {
                opacity: 1;
                transform: scaleX(-1);

                &:hover {
                    background: transparent;
                }

                svg {
                    width: rem(20);
                    height: rem(20);
                    background-color: transparent;
                    transform: translateX(45deg);
                }

                .circle-timer {
                    fill: transparent;
                    stroke: currentColor;
                    stroke-width: 30;
                    stroke-dashoffset: 500;
                    stroke-dasharray: 0;
                    animation: 5s circletimer linear forwards;
                }
            }
        }
    }

    // Slide animation
    .slick-slider {
        z-index: 1;

        .slick-slide {
            opacity: 0;
            transition: opacity 0.2s ease-in;

            &.slick-active {
                opacity: 1;
            }
        }
    }
}