/*
    Venue blocks
*/
.venue-blocks {
    .block {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: rem(40);

        @media (--from-medium-screen-w) {
            flex-wrap: nowrap;
            height: rem(250);
        }

        @media (--from-normal-screen-w) {
            height: rem(350);
        }
    }

    .block-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: $color-secondary;
        color: $color-tertiary;
        padding: rem(20);
        transition: background-color 0.2s ease-in;
        

        @media (--from-medium-screen-w) {
            display: block;
            width: calc(100% / 3);
            padding: rem(60) rem(20) rem(60) rem(60);
        }

        &:hover {
            text-decoration: none;
            background-color: $color-secondary-tint-1;
        }

        .title {
            font-family: $font-brandon-bold;
            font-size: rem(24);
            margin-bottom: rem(20);

            @media (--from-medium-screen-w) {
                font-size: rem(40);
            }
        }

        .address {
            font-size: rem(14);
            padding-left: rem(30);
            background: url($folder-icon/src/location-white.svg) no-repeat left;
        }
    }

    .block-direction {
        flex-basis: 50%;
        flex-grow: 1;

        @media (--from-medium-screen-w) {
            flex-basis: 0;
        }
    }

    .block-virtual-tour {
        width: 50%;
    }

    .block-direction,
    .block-virtual-tour {
        position: relative;
        background-position: center;
        background-size: cover;
        min-height: rem(200);

        @media (--from-medium-screen-w) {
            width: calc(100% / 3);
        }

        &:hover {
            .link-label {
                text-decoration: none;
                background-color: $color-secondary-tint-1;
                border-color: $color-secondary-tint-1;
            }
        }

        .link-label {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: rem(12);

            @media (--from-medium-screen-w) {
                font-size: rem(14);
            }

            &:hover {
                color: $color-tertiary;
                background-color: $color-secondary-tint-1;
                border-color: $color-secondary-tint-1;
            }
        }
    }
}