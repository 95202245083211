/*
    Section
*/
section.content-section {
    padding-top: rem(20);
    padding-bottom: rem(20);

    @media(--from-medium-screen-w) {
        padding-top: rem(45);
        padding-bottom: rem(45);
    }

    @media(--from-large-screen-w) {
        padding-top: rem(60);
        padding-bottom: rem(60);
    }

    &.whats-on,
    &.featured-shows {
        @media(--from-medium-screen-w) {
            padding-top: rem(30);
            padding-bottom: 0;
        }
    }

    &.centered,
    .centered {
        text-align: center;
    }

    .subheading {
        margin-bottom: rem(20);

        font-family: $font-brandon-regular;
        font-size: rem(18);
        text-transform: uppercase;

        @media(--from-medium-screen-w) {
            font-size: rem(20);
        }
    }

    .content-detail {
        color: $color-secondary-tint-1;
        font-size: rem(14);
        text-transform: uppercase;

        &:not(:last-child) {
            margin-bottom: rem(5);
        }

        .location,
        .price {
            padding-left: rem(25);

            background-position: left center;
            background-repeat: no-repeat;
            background-size: 18px auto;
        }

        .location {
            background-image: url($folder-icon/src/location.svg);
        }

        .price {
            background-image: url($folder-icon/src/price.svg);
        }
    }

    .description {
        @media(--from-medium-screen-w) {
            font-size: rem(20);
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    .subsection {
        padding-top: rem(10);
        padding-bottom: rem(10);
    
        @media(--from-medium-screen-w) {
            padding-top: rem(20);
            padding-bottom: rem(20);
        }
    
    
        // Relations
        & + & {
            padding-top: 0;
        }
    
    
        // States
        &:empty {
            display: none;
        }
    
        &:last-child {
            padding-bottom: 0;
        }
    }    
}