/*
    Page
 */
 *,
 *::before,
 *::after {
     box-sizing: border-box;
 }
 
html,
body {
    padding: 0;
    margin: 0;
}

html {
    position: relative;
    min-width: 320px;
    min-height: 100%;
}

body {
    width: 100%;
    overflow-x: hidden;

    background-color: $color-tertiary;

    &.is-fixed {
        position: fixed;
    }
}

.base {
    position: relative;
 
    &::before {
        position: absolute;
        top: 0;
        left: 0;

        display: block;
        width: 100%;
        height: rem(200);

        content: '';
    }
 }

.page {
    padding-top: rem($navigation-height-mobile);

    @media (--from-medium-screen-w) {
        padding-top: rem($navigation-height-tablet);
    }

    @media (--from-normal-screen-w) {
        padding-top: rem($navigation-height-desktop);
    }
}
 
.content-page {
    padding-bottom: rem(40);

    @media (--from-medium-screen-w) {
        padding-bottom: rem(60);
    }

    &.home-page,
    &.news-page {
        background-color: $color-quaternary;
    }

    &.show-search-results-page {
        .message {
            font-size: rem(16);
            text-align: center;
        }
    }
}

.page-banner {
    width: 100%;

    img {
        width: 100%;
    }
}

.page-header {
    padding: rem(45) 0 0;

    @media (--from-large-screen-w) {
        padding-top: rem(60);
    }

    .details,
    .subtitle,
    .credit {
        text-align: center;
    }

    .details {
        font-size: rem(14);
    }
}

hr {
    border: none;
    border-top: rem(1) solid $color-quaternary-shade-1;
}

.dialog-open {
    overflow: hidden;
} 