/*
    React slick
*/
.slick-slider {
    width: 100%;

    .slick-arrow {
        position: absolute;
        top: 50%;
        height: rem(50);
        max-height: rem(50);
        background-color: $color-quaternary-shade-1;
        border: none;
        border-radius: 0;
        opacity: 0;
        z-index: $layer-hero-images;
        transform-origin: center;

        &.slick-prev {
            left: 0;
            transform: translateY(-50%);

            .icon {
                transform: rotate(90deg)
            }
        }
        
        &.slick-next {
            right: 0;
            transform: translateY(-50%);

            .icon {
                transform: rotate(-90deg)
            }
        }

        .icon {
            height: rem(20);
        }
    }
}
