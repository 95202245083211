/*
    Form
*/
.form,
form {
    fieldset {
        padding: 0;
        margin: 0;
    
        border: 0 none;
    }

    input,
    optgroup,
    select,
    textarea {
        font-family: $font-primary;
        width: 100%;
        height: 100%;

        &:focus {
            outline: none;
        }
    }

    // 
    .input-wrapper,
    .select-wrapper {
        position: relative;
        height: 100%;

        &.with-icon input {
            padding-right: rem(40);
        }

        .label-icon {
            position: absolute;
            top: 0;
            right: 0;
            width: rem(40);
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-left: rem(1) solid $color-quaternary-shade-1;
            pointer-events:none;
        }

        svg.icon {
            width: rem(15);
            height: rem(15);
        }
    }

    select {
        padding-left: rem(13);
        padding-right: rem(40);
        border: none;
        border-radius: $border-radius;
        appearance: none;

        &::-ms-expand {
            display: none;
        }
    }

    // Field group

    // Inline field group
    .field-group.inline {
        display: flex;
        align-items: center;
        height: rem(40);

        input,
        .input {
            height: 100%;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        button.button {
            height: 100%;
            max-height: 100%;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    input,
    .input {
        width: 100%;
        padding-left: rem(13);
        min-height: rem(40);
    
        background-color: $color-tertiary;
        border: 1px solid $color-quaternary-shade-2;
        border-radius: $border-radius;
    
        appearance: none;
    
        &::placeholder {
            color: $color-quaternary-shade-3;
        }
    
        &-form-row {
            display: block;
            margin-bottom: rem(16);
        }
        
        &-label {
            display: block;
            margin-bottom: rem(4);
    
            font-family: $font-brandon-bold;
        }
    }
    
    .checkbox {
        position: absolute;
        left: -100vw; // Hide the native input element
    
    
        &-form-row {
            position: relative;
    
            display: flex;
        }
    
        &-icon {
            flex-grow: 0;
            flex-shrink: 0;
            width: rem(32);
            height: rem(32);
            margin-right: rem(4);
    
            color: $color-quaternary-shade-2;
        }
    
        &:focus + &-icon {
            color: $color-primary;
        }
    
        &-label {
            display: block;
            flex-grow: 1;
            padding-top: rem(7);
    
            line-height: 1.2;
    
            &-value {
                margin-right: rem(6);
    
                font-family: $font-brandon-bold;
            }
    
            &-description {
                color: $color-secondary-tint-1;
            }
        }
    }
    
    .faux-required-input {
        display: block;
        width: 0;
    
        &-form-row {
            display: block;
            height: 0;
    
            overflow: hidden;
        }
    }
}