/*
    Show finder
*/
.show-finder {
    display: none;
    padding: rem(15) 0;

    @media (--from-normal-screen-w) {
        display: block;
    }

    &-wrapper {
        color: $color-secondary;
        background-color: $color-quaternary;
    }

    fieldset {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .label {
        display: inline-block;
        margin: 0 rem(10);
    }

    button {
        @media (--from-normal-screen-w) {
            margin-left: rem(10);
        }
    }

    .select-wrapper,
    .field-group,
    .date-input-wrapper,
    .input-wrapper {
        width: rem(200);
        height: rem(40);

        @media (--from-normal-screen-w) {
            width: auto;
            min-width: rem(200);
        }
        
        .label-icon {
            color: $color-secondary;
        }
    }

    input,
    select {
        background-color: $color-tertiary;
    }

    .input {
        border: none;
    }
    
    // Search form on dialog
    &&-dialog {
        display: block;
        width: rem(300);
        max-width: 100%;
        height: auto;
        color: $color-secondary;

        fieldset {
            position: relative;
            flex-direction: column;

            & > * {
                margin-bottom: rem(20);
            }
        }

        .field-group span.label {
            position: absolute;
            left: 0;
        }

        .button.clear-dates,
        .button[type="submit"] {
            width: rem(150);
        }
    }
}