/*
    Specifications
*/
.specifications {
    list-style: none;

    .item {
        font-family: $font-brandon-regular;
        font-size: rem(16);
        line-height: rem(25);
        text-align: left;
        padding-left: rem(35);
        margin-bottom: rem(15);
        background-repeat: no-repeat;
        background-position: left;


        @media (--from-medium-screen-w) {
            font-size: rem(20);
            line-height: rem(32);
            background-position: left rem(10);
        }

        // Types
        &.artists {
            background-image: url($folder-icon/src/microphone.svg);
        }

        &.director {
            background-image: url($folder-icon/src/director.svg);
        }

        &.duration {
            background-image: url($folder-icon/src/duration.svg);
        }

        &.age-restriction {
            background-image: url($folder-icon/src/age-restriction.svg);
        }

        &.wheelchair-accessible {
            background-image: url($folder-icon/src/wheelchair.svg);
        }

        &.touch-tours {
            background-image: url($folder-icon/src/touch-tours.svg);
        }

        &.audio-described {
            background-image: url($folder-icon/src/audio-described.svg);
        }
        &.virtual-tours {
            background-image: url($folder-icon/src/virtual-tour.svg);
        }

        &.hearing-aid-loop {
            background-image: url($folder-icon/src/hearing-loop.svg);
        }

        &.sign-language-interpreted {
            background-image: url($folder-icon/src/sign-language.svg);
        }
    }
}

h3.key-information {
    width: fit-content;
    margin: auto;
    text-transform: uppercase;
    font-size: rem(14);
    position: relative;
    top: rem(-20);
    background-color: $color-tertiary;
    padding: rem(10);
    font-family: $font-brandon-bold;

    @media (--from-medium-screen-w) {
        display: none;
    }
}

.additional-information {
    border-top: rem(1) solid $color-quaternary;
    padding: rem(30) rem(20) 0;

    @media (--from-medium-screen-w) {
        padding: rem(30) 0;
    }

    p {
        font-family: $font-brandon-regular;
        font-size: rem(16);
        line-height: rem(25);
        text-align: left;
    }
}