/*
    Animation
*/

// Navigation sub items
@keyframes subNavIn {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

// Circle timer
@keyframes circletimer {
    0% {
        stroke-dashoffset: 0;
        stroke-dasharray: 500;
    }
    100% {
        stroke-dashoffset: 500;
        stroke-dasharray: 500;
    }
}

// Image reveal
@keyframes imgReveal {
    50% {
        transform: scale(1.2);
        opacity: 0;
    }
    51% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

// Text in
@keyframes textIn {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

// Fade out
@keyframes textOut {
    0% {
        width: 100%;
        height: auto;
    }

    99% {
        height: auto;
    }

    100% {
        width: 0;
        height: 0;
    }
}

// Logo
@keyframes dashOut {
    0% {
        stroke-dashoffset: 0;
        stroke-dasharray: 1000;
    }
    100% {
        stroke-dashoffset: 1000;
        stroke-dasharray: 1000;
    }
}

@keyframes dashIn {
    0% {
        stroke-dashoffset: 1000;
        stroke-dasharray: 1000;
    }
    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 1000;
    }
}