/*
    Content blocks
*/
.content-blocks {
    padding-top: rem(20);

    @media (--from-medium-screen-w) {
        padding-top: rem(50);
    }

    .content-block {
        margin-bottom: rem(50);
    }
}