/*
    Media
*/
picture,
img,
svg {
    height: auto;
    max-width: 100%;
}

use {
    width: 100%;
    height: 100%;
}

img {
    display: block;

    &.cover {
        object-fit: cover;
        object-position: center;
    }

    &.contain {
        object-fit: contain;
        object-position: center;
    }
}

picture {
    display: block;

    img {
        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center;
    }
}

figure {
    margin: 0;
}

iframe {
    width: 100%;

    border: 0;
}
 