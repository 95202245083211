/*
    Style guide
*/
.style-guide-page {
    .section {
        margin-bottom: rem(50);

        &:last-child {
            margin-bottom: 0;
        }
    }

    // Section
    .color-swatch {
        ul.colours {
            display: flex;
            flex-wrap: wrap;
            list-style-type: none;
            padding-left: 0;
        }

        li {
            width: rem(60);
            height: rem(60);
            margin-right: rem(10);
            margin-bottom: rem(10);

            &.primary {
                background-color: $color-primary;
            }
    
            &.primary-tint-1 {
                background-color: $color-primary-tint-1;
            }
    
            &.secondary {
                background-color: $color-secondary;
            }
    
            &.secondary-tint-1 {
                background-color: $color-secondary-tint-1;
            }
    
            &.tertiary {
                background-color: $color-tertiary;
                border: rem(1) solid $color-secondary;
            }
    
            &.quaternary {
                background-color: $color-quaternary-shade-1;
            }
        }
    }

    .buttons {
        &-group {
            margin-bottom: rem(10);
        }

        button.button {
            display: inline-block;
            margin-right: rem(10);
        }
    }
}