/*
    Share block
*/
.share-block {
    display: none;
    position: absolute;
    top: 0;
    right: rem(20);
    color: $color-tertiary;
    background-color: $color-secondary;
    padding: rem(5);
    border-radius: rem(4);
    cursor: pointer;
    transform: translateY(10px);

    @media (--from-medium-screen-w) {
        display: flex;
    }
    
    .links,
    .toggle {
        height: rem(30);
    }

    .link {
        color: $color-tertiary;
        display: inline-block;
        margin: 0 rem(5);
        padding-top: rem(5);
        transition: color 0.2s ease;

        &:hover {
            color: $color-primary;
        }
    }


    &:hover {
        .links {
            width: auto;
            opacity: 1;
            padding: 0 rem(5);
        }

        .toggle {
            color: $color-primary;
        }
    }

    .links {
        width: 0;
        opacity: 0;
        border-right: rem(1) solid;
        transition: all .2s ease-in-out;
    }

    .icon {
        width: rem(18);
        height: rem(18);
    }

    .toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 rem(8);
        transition: color 0.2s ease;

        & > * {
            display: inline-block;
            margin: 0 rem(5);
        }
    }
}