/*
    Newsletter signup content block
*/
.newsletter-signup-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    padding: rem(12);

    background-image: url($folder-image/page/newsletter-signup-background.jpg);
    background-color: $color-secondary;
    background-size: cover;
    box-shadow: 1px 1px 4px 0 $color-quaternary-shade-3-020;

    @media (--from-medium-screen-w) {
        padding: rem(20) rem(12);
    }

    @media (--from-large-screen-w) {
        padding: rem(56) rem(40);
    }

    .details {
        flex-basis: 100%;
        color: $color-tertiary;

        @media (--from-medium-screen-w) {
            flex-basis: 60%;
            max-width: rem(540);
        }

        @media (--from-large-screen-w) {
            flex-basis: 50%;
        }

        p {
            font-size: rem(14);

            @media (--from-medium-screen-w) {
                margin-bottom: 0;
                font-size: rem(16);
            }

            @media (--from-normal-screen-w) {
                font-size: rem(20);
            }
        }
    }

    .signup-form {
        flex-basis: 100%;

        @media (--from-medium-screen-w) {
            flex-basis: 33%;
            max-width: rem(360);
        }

        @media (--from-normal-screen-w) {
            flex-basis: 35%;
        }

        @media (--from-large-screen-w) {
            flex-basis: 33%;
        }

        .form {
            margin-bottom: rem(15);

            @media (--from-normal-screen-w) {
                margin-bottom: rem(22);
            }
        }
    }

    .newsletter-signup-form .field-group {
        height: rem(45);
    }

    .privacy-policy {
        margin-bottom: 0;
        color: $color-tertiary;
        font-size: rem(14);

        @media (--from-normal-screen-w) {
            font-size: rem(16);
        }
    }
}

// For large constrain width page eg. home page
.constrain-width.large .newsletter-signup-block {
    .details,
    .signup-form {
        max-width: unset;
    }
}
