/*
    Pricing table
*/
.pricing-table {
    .pricing-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: rem(20);
    }

    .title {
        margin-bottom: rem(10);
    }

    p.description {
        font-size: rem(14);
    }

    .price {
        font-family: $font-brandon-bold;
        font-size: rem(18);
        flex-shrink: 0;
        margin-left: rem(20);

        @media (--from-medium-screen-w) {
            font-size: rem(24);
        }
    }
}