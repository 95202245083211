/*
    Tile
*/
.tile-verticle {
    position: relative;
    display: flex;
    margin: rem(5);
    background-color: $color-tertiary;
    border: 1px solid $color-quaternary-shade-1;
    box-shadow: 1px 1px 4px 0 $color-quaternary-shade-3-020;
    color: $color-secondary;

    @media (--from-medium-screen-w) {
        display: block;
        overflow: hidden;
        margin: rem(10);
        min-height: rem(200);
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: calc(33% - 22px);
    }

    @media (--from-normal-screen-w) {
        flex-basis: calc(25% - 22px);
    }

    &:hover {
        text-decoration: none;

        & .thumbnail-page {
            @media (--from-medium-screen-w) {
                transform: scale(1.02);
            }
        }

        & .tile-thumbnail::before {
            @media (--from-medium-screen-w) {
                opacity: 0.3;
            }
        }

        & .information {
            @media (--from-medium-screen-w) {
                transform: translateY(-80px);
            }
        }
    }

    &.has-presenter {
        padding-top: rem(25);

        @media(--from-medium-screen-w) {
            padding-top: 0;
        }
    }

    .presenter {
        font-size: rem(12);
        color: $color-tertiary;
        background-color: $color-secondary;
        padding: rem(5) rem(10);
        position: absolute;
        height: rem(25);
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (--from-medium-screen-w) {
            height: auto;
            opacity: 0.8;
        }
    }

    // Thumbnail for event/show tile
    .tile-thumbnail {
        width: 50%;
        position: relative;
        z-index: 0;

        @media (--from-medium-screen-w) {
            width: 100%;
            height: 0;
            padding-top: calc( 400 / 640 * 100%);
            overflow: hidden;
        }

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $color-secondary;
            opacity: 0;
            transition: all .25s ease-in-out;
            z-index: 1;
        }

        .image {
            width: 100%;
            min-height: 100%;
            object-fit: cover;
            object-position: center;

            @media (--from-medium-screen-w) {
                position: absolute;
                top: 0;
                height: 100%;
                transition: transform .25s ease-in-out;
            }

            &.desktop {
                display: none;

                @media (--from-small-screen-w) {
                   display: block;
                }
            }

            &.mobile {
                display: block;

                @media (--from-small-screen-w) {
                   display: none;
                }
            }
        }
    }

    .information {
        width: 50%;
        padding: rem(6) rem(10);        
        background-color: $color-tertiary;
        transition: transform .25s ease-in-out;
        display: flex;
        flex-direction: column;

        @media (--from-small-screen-w) {
            padding: rem(12);
        }

        @media (--from-medium-screen-w) {
            position: relative;
            display: block;
            width: 100%;
            height: rem(140);
            padding: rem(18);
            padding-bottom: rem(10);
        }

        .title {
            font-size: rem(16);
            margin-top: 0;
            margin-bottom: auto;

            @media (--from-medium-screen-w) {
                display: -webkit-box;
                max-width: 100%;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                margin-bottom: rem(10);
            }

            @media (--from-large-screen-w) {
                font-size: rem(20);
            }
        }

        .date.past {
            color: $color-primary;
        }

        .presenter {
            top: 0;
            left: 0;
            width: 100%;

            @media (--from-medium-screen-w) {
                transform: translateY(-100%);
            }
        }

        .type {
            position: absolute;
            left: 0;
            bottom: 0;
            color: $color-tertiary;
            background-color: $color-secondary;
            font-size: rem(14);
            padding: rem(5) rem(10);
            margin-bottom: 0;

            @media (--from-medium-screen-w) {
                top: 0;
                left: 0;
                bottom: auto;
                transform: translateY(-100%);
            }

            &-news {
                background-color: $color-primary;
            }
        }

        .price,
        .location,
        .genre,
        .date {
            font-size: rem(14);
            margin: 0;
        }

        .genre {
            display: none;

            @media (--from-medium-screen-w) {
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        & > .genre {
            width: calc(100% - rem(10));
        }

        .sub-information {
            width: 100%;

            @media (--from-medium-screen-w) {
                position: absolute;
                left: 0;
                bottom: rem(10);
                padding: 0 rem(18);
            }
        }

        .location {
            display: block;
            max-width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .description {
            font-size: rem(14);
            line-height: 1.4;
            display: -webkit-box;
            max-width: 100%;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-align: left;
            margin-bottom: 0;
        }

        // For venue tile
        .sub-venues {
            font-size: rem(14);
            max-width: 100%;
            display: -webkit-box;
            max-width: 100%;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: 0;
        }

        .further {
            display: flex;
            flex-direction: column;
            justify-content: center;

            @media (--from-medium-screen-w) {
                position: absolute;
                background-color: $color-tertiary;
                top: 100%;
                left: 0;
                width: 100%;
                height: rem(80);
                padding: rem(10) rem(18);
                border-top: rem(1) solid $color-quaternary-shade-1;
            }

            // For event / venue tile
            .location {
                padding-left: rem(25);
                background: url($folder-icon/src/location.svg) no-repeat left center;
                background-size: rem(18) auto;
            }

            .price {
                padding-left: rem(25);
                background: url($folder-icon/src/price.svg) no-repeat left center;
                background-size: rem(18) auto;
            }
        }
    }

    // News information
    .information-news {
        .title {
            margin-top: rem(25);
            margin-bottom: rem(10);

            @media (--from-medium-screen-w) {
                margin-top: 0;
                margin-bottom: auto;
            }
        }

        .sub-information {
            position: absolute;
            top: 0;

            @media (--from-medium-screen-w) {
                top: unset;
                bottom: rem(10);
            }
        }
    }

    // Page tile
    &.tile-page {
        .title-page {
            color: $color-tertiary;
            background-color: $color-secondary;
            padding: rem(15);
            margin-bottom: 0;
            text-align: left;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
    
    
            @media (--from-medium-screen-w) {
                position: absolute;
                bottom: 0;
                width: 100%;
            }

            h3 {
                font-size: rem(16);
                margin-bottom: 0;                    width: fit-content;

                @media (--from-medium-screen-w) {
                    display: -webkit-box;
                    max-width: 100%;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }
    
        .tile-thumbnail__page {
            width: 50%;
            transition: all .25s ease-in-out;
    
            @media (--from-medium-screen-w) {
                width: 100%;
                height: 100%;
            }
    
            .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}