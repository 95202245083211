/*
    Parking availability
*/
.parking-availability-block {
    .title {
        font-family: $font-brandon-medium;
    }

    .parking-availability {
        border-top: 1px solid $color-quaternary-shade-1;

        .inner {
            display: flex;
            flex-direction: column;
            padding-top: rem(20);
            padding-bottom: rem(20);

            @media (--from-normal-screen-w) {
                justify-content: space-between;
                flex-direction: row;
            }
        }
    }

    .carpark {
        flex-basis: calc(100% / 3);

        @media(--from-normal-screen-w) {
            margin: 0 rem(-10);
        }
        &-inner {
            margin-bottom: rem(10) 0;

            @media(--from-normal-screen-w) {
                margin: 0 rem(10);
            }
        }

        &-tile {
            display: block;
            padding-top: rem(15);
            padding-right: rem(15);
            padding-bottom: rem(15);
            padding-left: rem(15);
    
            box-shadow: 1px 1px 4px 0 $color-quaternary-shade-3-050;
    
            transition: box-shadow ease-in-out 150ms;
    
            @media(--from-medium-screen-w) {
                padding-top: rem(20);
                padding-right: rem(40);
                padding-bottom: rem(20);
                padding-left: rem(40);
            }
    
            &,
            &:link,
            &:visited,
            &:hover,
            &:active,
            &:focus {
                color: inherit;
                text-decoration: none;
            }
    
            &:hover,
            &:active,
            &:focus {
                box-shadow: 1px 3px 10px 0 $color-quaternary-shade-3-050;
    
                .carpark-note-highlight {
                    text-decoration: underline;
                }
            }
        }
    
        &-label {
            margin-bottom: rem(4);
    
            font-family: $font-brandon-medium;
            font-size: rem(16);
    
            @media(--from-medium-screen-w) {
                font-size: rem(20);
            }
        }
    
        &-name {
            margin-bottom: rem(10);
    
            color: $color-primary;
            font-family: $font-brandon-bold;
            font-size: rem(28);
            line-height: 1.4;
    
            border-bottom: 1px solid $color-quaternary-shade-1;
    
            @media(--from-medium-screen-w) {
                font-size: rem(40);
            }
        }
    
        &-available-spaces {
            margin-bottom: rem(4);
    
            font-family: $font-brandon-bold;
            font-size: rem(28);
            line-height: 1.4;
    
            @media(--from-medium-screen-w) {
                font-size: rem(40);
            }
        }
    
        &-note-highlight {
            color: $color-primary;
        }
    }
}