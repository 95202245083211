/*
    Tabs
*/
.tabs-wrapper {
    .tabs {
        &-tab-list {
            list-style: none;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: rem(1) solid $color-quaternary-shade-1;
            margin-bottom: 0;
            padding-left: 0;
            width: 100%;
            background-color: $color-tertiary;
            position: relative;

            &.is-fixed {
                position: fixed;
                top: rem(115);
                left: 0;
                z-index: 1;

                @media (--from-medium-screen-w) {
                    top: rem(65);
                }

                @media (--from-normal-screen-w) {
                    top: rem(135);
                }
            }
        }
    
        &-tab {
            font-family: $font-brandon-medium;
            font-size: rem(14);
            padding: rem(10);
            border-top: rem(5) solid $color-tertiary;
            border-left: rem(1) solid $color-quaternary-shade-1;
            cursor: pointer;
            text-align: center;
            color: $color-maintenance;
            flex: 1;
    
            @media (--from-medium-screen-w) {
                height: rem(60);
                font-size: rem(20);
                padding: rem(10) rem(20);
                flex: 0 auto;
            }
    
            &:last-of-type {
                border-right: rem(1) solid $color-quaternary-shade-1;
            }
    
            &.is-active {
                color: $color-secondary;
                border-bottom: rem(5) solid $color-secondary;
    
                @media (--from-medium-screen-w) {
                    border-top: rem(5) solid $color-secondary;
                    border-bottom: none;
                }
            }
        }
    }

    // New Tabs
    .tabs.is-new {
        .tabs-tab-list {
            border-color: $color-secondary;
        }

        .tabs-tab {
            position: relative;
            height: fit-content;

            &.is-active {
                border: rem(2) solid $color-secondary;
                border-bottom: 0;

                &::after {
                    display: block;
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transform: translateY(100%);
                    width: 100%;
                    height: rem(1);
                    background-color: $color-tertiary;
                }
            }
        }
    }
}
