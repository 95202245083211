/*
    Type
 */
 html {
    color: $color-secondary;
    font-family: $font-primary;
    font-size: 16px;
    font-smoothing: subpixel-antialiased;
    font-weight: $weight-regular;
    line-height: 1.6;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Reset */
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading-4,
p {
    margin-top: 0;
}



// Headings
h1,
h2,
h3,
h4,
h5,
h6,
.heading-4 {
    margin-bottom: rem(15);

    font-weight: $weight-regular;


    // Relations
    & + hr {
        margin-bottom: rem(10);
    }


    // Types
    &.condensed {
        margin-bottom: rem(4);

        @media (--from-small-screen-w){
            margin-bottom: rem(6);
        }
    }

    &.bordered {
        position: relative;

        padding-bottom: rem(20);

        text-align: center;

        &:after {
            position: absolute;
            bottom: 0;
            left: 50%;

            display: block;
            width: rem(60);
            height: rem(6);

            background-color: currentColor;
            content: '';

            transform: translateX(-50%);
        }
    }
}

h1,
h2,
h3 {
    line-height: 1.4;
}

h4,
h5,
h6,
.heading-4 {
    line-height: 1.2;
}

h2,
h3,
h4,
h5,
.heading-4 {
    font-family: $font-brandon-medium;

    em {
        font-family: $font-brandon-medium-italic;
    }
}


h1 {
    font-family: $font-brandon-black;
    font-size: rem(28);

    @media(--from-medium-screen-w) {
        font-size: rem(30);
    }

    @media(--from-normal-screen-w) {
        font-size: rem(40);
    }

    em {
        font-family: $font-brandon-black-italic;
    }
}

h2 {
    font-size: rem(24);

    @media(--from-medium-screen-w) {
        font-size: rem(28);
    }

    @media(--from-normal-screen-w) {
        font-size: rem(34);
    }
}

h3 {
    font-size: rem(22);

    @media(--from-medium-screen-w) {
        font-size: rem(30);
    }
}

h4, .heading-4 {
    font-size: rem(18);

    @media(--from-medium-screen-w) {
        font-size: rem(26);
    }
}

h5 {
    font-size: rem(16);

    @media(--from-medium-screen-w) {
        font-size: rem(18);
    }
}

h6 {
    font-family: $font-brandon-bold;
    font-size: rem(14);

    em {
        font-family: $font-brandon-bold-italic;
    }
}



// Normal text
p {
    margin-bottom: rem(15);

    // Relations
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6 {
        margin-top: rem(30);
    }
}

strong {
    font-family: $font-brandon-bold;
    font-weight: $weight-regular;

    em {
        font-family: $font-brandon-bold-italic;
    }
}

em {
    font-family: $font-brandon-regular-italic;
    font-style: normal;

    strong {
        font-family: $font-brandon-bold-italic;
    }
}

blockquote,
q {
    display: block;
    margin-top: 0;
    margin-bottom: rem(30);

    font-family: $font-brandon-black-italic;
    font-size: rem(20);
    line-height: 1.2;

    @media(--from-small-screen-w) {
        font-size: rem(28);
    }

    @media(--from-normal-screen-w) {
        font-size: rem(34);
    }

    /* Presceding elements */
    & + cite {
        margin-top: rem(-15);
    }
}

cite {
    display: block;
    margin-bottom: rem(20);

    font-size: rem(18);
    font-style: normal;

    &:before {
        content: '- ';
    }
}

address {
    font-style: normal;
}

small {
    font-size: rem(14);

    &.primary {
        display: block;
        margin-bottom: rem(15);
    }
}



/* List */
ul,
ol {
    padding-left: rem(20);
    margin-top: 0;
    margin-bottom: rem(20);
}



/* Link */
a {
    color: $color-primary;
    text-decoration: none;

    a[href^=tel] {
        color: inherit;
    }

    &:hover {
        text-decoration: underline;
    }
}



/* In-page anchors */
:target::before {
    display: block;
    height: rem($space-above-in-page-anchors);
    margin-top: rem(-$space-above-in-page-anchors);

    content: '';
}



/* Sizing */
.text-medium {
    font-size: rem(18);
}



/* Transformation */
.text-uppercase {
    letter-spacing: 0.02em;
    text-transform: uppercase;
}

.text-lowercase {
    text-transform: lowercase;
}



/* Alignment */
.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}
