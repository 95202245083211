/*
    Tiles slider
*/
.tiles-slider {
    width: 100%;
    overflow: hidden;

    &-container {
        margin: 0 rem(-10);
        width: calc(100% - rem(20));

        @media (--from-medium-screen-w) {
            width: 100%;
        }

        .slick-slider {
            margin-bottom: rem(20);

            .slick-list {
                overflow: visible;

                @media (--from-medium-screen-w) {
                    overflow: hidden;
                }
            }

            .slick-track {
                margin-left: 0;
            }

            .slick-slide > div {
                display: flex;
            }
        }

        .paging {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: rem(20);

            @media (--from-medium-screen-w) {
                justify-content: center;
                margin-left: 0;
            }

            .details {
                margin: 0 rem(20);
            }

            .slick-arrow {
                width: rem(30);
                height: rem(30);
                padding: 0;
                border-radius: 100%;
                transform-origin: center;

                .icon {
                    margin: 0;
                }

                &:not([disabled]) {
                    color: $color-secondary;
                }

                &__prev {
                    .icon {
                        transform: rotate(90deg);
                    }
                }

                &__next {
                    .icon {
                        transform: rotate(-90deg);
                    }
                }
            }
        }
    }
}