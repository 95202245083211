/*
    Pagebreak quote
*/
.pagebreak-quote-block {
    height: rem(280);

    @media (--from-normal-screen-w) {
        height: rem(350);
    }

    .inner {
        position: relative;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.85;
        }

        .content-wrapper {
            position: relative;
            width: 100%;
            color: $color-tertiary;
            padding: rem(50) rem(30) rem(30);

            @media (--from-small-screen-w) {
                padding-left: rem(80);
            }

            @media (--from-normal-screen-w) {
                padding-left: rem(150);
            }
        }

        blockquote {
            font-size: rem(20);
            max-width: 100%;
            margin: 0 0 rem(30);
            line-height: 1.2;

            @media (--from-small-screen-w) {
                max-width: rem(640);
                font-size: rem(28);
            }

            @media (--from-normal-screen-w) {
                font-size: rem(34);
            }
        }

        cite {
            margin-left: 0;
            text-align: left;

            &::after{
                content: none
            }
        }

        .quote-marks {
            display: inline-block;

            &.open {
                width: rem(20);
                height: rem(20);
                margin-right: rem(10);

                @media (--from-small-screen-w) {
                    width: rem(35);
                    height: rem(35);
                }
            }

            &.close {
                transform: rotate(180deg);
                margin-left: rem(10);
                width: rem(15);
                height: rem(15);
            }
        }

        cite {
            font-size: rem(14);

            @media (--from-normal-screen-w) {
                font-size: rem(18);
            }
        }

        // Theme
        &.theme-blue:before {
            background-color: $color-secondary;
        }

        &.theme-green:before {
            background-color: $color-theme-green;
        }

        &.theme-red:before {
            background-color: $color-primary;
        }

        &.theme-yellow:before {
            background-color: $color-theme-yellow;
        }
    }
}