/*
    Hero
*/
.hero {
    background-color: $color-quaternary;

    @media (--from-medium-screen-w) {
        padding-bottom: rem(20);
    }

    // Theme
    &.theme-default,
    &.theme-blue {
        .hero-information {
            background-color: $color-secondary;
        }
    }

    &.theme-green {
        .hero-information {
            background-color: $color-theme-green;
        }
    }

    &.theme-red {
        .hero-information {
            background-color: $color-primary;
        }
    }

    &.theme-yellow {
        .hero-information {
            color: $color-secondary;
            background-color: $color-theme-yellow;
        }

        .price {
            background: url($folder-icon/src/price-black.svg) no-repeat left center;
        }

        .location {
            background: url($folder-icon/src/location-black.svg) no-repeat left center;
        }

        .phone {
            background: url($folder-icon/src/phone-black.svg) no-repeat left center;
        }
    }

    &.theme-default,
    &.theme-blue,
    &.theme-green,
    &.theme-red {
        .price {
            background: url($folder-icon/src/price-white.svg) no-repeat left center;
        }

        .location {
            background: url($folder-icon/src/location-white.svg) no-repeat left center;
        }

        .phone {
            background: url($folder-icon/src/phone-white.svg) no-repeat left center;
        }
    }

    .inner {
        display: flex;
        flex-direction: column;

        @media (--from-medium-screen-w) {
            flex-direction: row;
        }
    }

    &-information {
        order: 2;
        color: $color-tertiary;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        padding: rem(20);
        margin-top: rem(-10);

        @media (--from-medium-screen-w) {
            flex-basis: 40%;
            order: 1;
            padding: rem(20) rem(30);
            margin-top: 0;
        }

        @media (--from-normal-screen-w) {
            flex-basis: 30%;
        }

        @media (--from-large-screen-w) {
            padding: 0 rem(50);
        }

        .heading {
            font-family: $font-brandon-medium;
            font-size: rem(24);
            margin-top: 0;

            @media (--from-normal-screen-w) {
                font-size: rem(28);
            }

            @media (--from-large-screen-w) {
                font-size: rem(32);
            }
        }

        .price,
        .location,
        .phone {
            padding-left: rem(30);
        }

        .date,
        .price,
        .location,
        .phone {
            font-size: rem(14);
        }

        .date {
            text-transform: uppercase;

            .past-label {
                display: inline-block;
                padding-right: 8px;
                margin-right: 8px;
    
                border-right: 1px solid currentColor;
            }
        }

        .genre {
            font-family: $font-brandon-bold;
            font-size: rem(12);
            text-transform: uppercase;
            margin-top: rem(20);

            @media (--from-medium-screen-w) {
                margin-top: rem(20);
            }
        }

        .button{
            color: inherit;
            align-self: flex-start;
            margin-top: rem(20);

            @media (--from-medium-screen-w) {
                margin-top: rem(40);
            }
        }
    }
    
    &-image {
        overflow: hidden;
        order: 1;

        @media (--from-medium-screen-w) {
            order: 2;
            flex-basis: 60%;
        }

        @media (--from-normal-screen-w) {
            flex-basis: 70%;
        }

        &-wrapper {
            position: relative;
            padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
            background-size: cover;
            background-position: center;
            min-height: 100%;
        }

        .video-player {
            position: absolute;
            top: 0;
            left: 0;
            display: none;
            
            @media (--from-medium-screen-w) {
                display: block;
            }
        }

        .image {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}