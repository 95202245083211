/*
    Footer
*/
footer.footer-wrapper {
    padding-bottom: rem(100);

    @media (--from-tiny-screen-w) {
        padding-bottom: rem(80);
    }

    @media (--from-medium-screen-w) {
        padding-bottom: rem(60);
    }

    section {
        border-top: rem(1) solid $color-quaternary-shade-1;
    }

    // Partners
    .partners {
        display: none;

        @media (--from-medium-screen-w) {
            display: block;
            padding: rem(30) 0;
            text-align: center;
        }

        .heading {
            color: $color-dusty;
            text-transform: uppercase;
            margin-bottom: rem(20);
        }
    }

    .logo-list {
        display: flex;
        justify-content: space-between;
        align-items: center;

        a.logo {
            display: block;
            transition: opacity 0.2s ease-in;

            &:hover {
                opacity: 0.6;
            }

            img {
                width: 100%;
                height: 100%;
                max-width: rem(80);
                max-height: rem(80);
                object-fit: contain;
                object-position: center;
            }

            &.extra-wide img {
                max-width: rem(160);
                max-height: rem(80);
            }
        }
    }

    // Primary partners
    .partners.primary {
        .logo-list {
            justify-content: center;
        }

        .item {
            & .logo {
                width: rem(200);

                img {
                    max-width: unset;
                    max-height: unset;
                }
            }

            .logo-description {
                color: $color-dusty;
                margin: rem(10) 0 0 0;
            }
        }
    }

    // Blocks
    .blocks {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .heading {
            text-transform: uppercase;
        }

        .block {
            a[href^=tel] {
                color: currentColor;
            }
    
            a[href^=tel]:hover {
                text-decoration: none;
            }
    
            p {
                margin-bottom: 0;
            }

            &.logo {
                width: rem(220);

                &.rfa-logo {
                    padding: 0 rem(20);
                        
                    @media (--from-medium-screen-w) {
                        padding: 0 rem(40) 0 0;
                    }
                }

                img {
                    max-height: rem(120);
                    width: 100%;
    
                    &.desktop-logo {
                        display: none;
    
                        @media (--from-medium-screen-w) {
                            display: block;
                        }
                    }
    
                    &.mobile-logo {
                        display: block;
    
                        @media (--from-medium-screen-w) {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    // Contact information
    .contact-information {
        .blocks {
            flex-direction: column;
            align-items: flex-start;

            @media (--from-medium-screen-w) {
                flex-direction: row;
                align-items: stretch;
            }

            .block {
                padding: rem(30) 0;
                margin: aut0;

                &:last-child {
                    border: none;
                }

                @media (--from-medium-screen-w) {
                    flex-basis: 33%;
                    border-right: rem(1) solid $color-quaternary-shade-1;
                }
    
                .content,
                .content > * {
                    width: fit-content;
                }

                .content {
                    margin: auto;
                }

                &.general-enquiries .content {
                    margin-left: 0;
                }

                .tel,
                .mail {
                    padding-left: rem(25);
                    background-position: left center;
                    background-repeat: no-repeat;
                    background-size: 18px auto;
                }

                .tel {
                    background-image: url($folder-icon/src/phone-black-bg.svg);
                }

                .mail {
                    background-image: url($folder-icon/src/mail-black-bg.svg);
                }
            }
        }
    }

    // Page information
    .page-information {
        padding: rem(30) 0;

        .blocks {
            flex-direction: column-reverse;;
            align-items: center;

            @media (--from-medium-screen-w) {
                flex-direction: row;
            }
        }

        .block {
            margin-bottom: rem(30);

            @media (--from-medium-screen-w) {
                margin-bottom: 0;
            }
        }

        .block.social-links {
            text-align: center;
        }

        .social-icons {
            display: flex;
            justify-content: center;

            .link {
                display: block;
                color: $color-secondary;
                margin: 0 rem(10);
                transition: color 0.2s;

                &:hover {
                    color: $color-primary;
                }
            }

            .icon {
                width: rem(30);
                height: rem(30);

                @media (--from-medium-screen-w) {
                    width: rem(20);
                    height: rem(20);
                }
            }
        }
    }
    
    .copy-rights {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: rem(14);
        text-align: center;
        margin-bottom: rem(20);

        @media (--from-small-screen-w) {
            flex-direction: row;
        }

        & > * {
            position: relative;
            padding: 0 5px;

            &::after {
                content: '';
                display: none;
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 15px;
                background-color: black;
                transform: translateY(-50%);

                @media (--from-small-screen-w) {
                    display: inline-block;
                }
            }

            &:last-child::after {
                display: none;
            }
        }
    }
}