/*
    Whats on and Featured shows sections
*/
.whats-on,
.featured-shows {
    position: relative;
    margin: 0 rem(-10);
    margin: auto;

    .heading {
        @media (--from-medium-screen-w) {
            position: relative;
        }

        .title {
            text-align: center;

            @media (--from-medium-screen-w) {
                text-align: left;
            }
        }
    }

    .view-all-btn {
        position: absolute;
        right: rem(20);
        bottom: rem(20);
        
        @media (--from-medium-screen-w) {
            bottom: unset;
            top: 0;
        }
    }

    .tile-slider {
        max-width: rem(200);

        @media (--from-tiny-screen-w) {
            max-width: unset;
        }
    }
}