/*
    Accordion
*/
.accordion {
    .item {
        margin-bottom: rem(20);

        .heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $color-primary;
            cursor: pointer;

            .icon {
                width: rem(20);
                height: rem(20);
                transition: transform 0.2s ease-in-out;
            }
        }

        .information,
        .information p {
            font-size: rem(16);
        }

        .content {
            display: none;
        }

        &.is-active {
            .content {
                display: block;
            }

            .icon {
                transform: rotate(180deg);
                transform-origin: center;
            }
        }
    }
}