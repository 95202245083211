/*
    Performance timetable
*/
.performance-timetable {
    position: relative;

    .view-button-group {
        position: absolute;
        right: rem(20);
        display: none;
        z-index: 1;

        @media (--from-normal-screen-w) {
            display: block;
        }

        .button {
            color: $color-secondary;
            margin: 0 rem(5);
            background-color: $color-tertiary;
            padding: rem(5);

            &:hover {
                color: $color-secondary;
                background-color: $color-tertiary;
            }

            svg {
                width: rem(20);
                height: rem(20);
                margin: 0;
            }
        }

        .button.is-active {
            color: $color-primary;
            background-color: $color-tertiary;

            &:hover {
                color: $color-primary;
                background-color: $color-tertiary;
                border-color: $color-primary;
            }
        }
    }

    .timetable-list {
        background-color: $color-quaternary-shade-1;
        padding: rem(20) 0;
    }

    .performance-tabs {
        .tabs-tab {
            text-transform: uppercase;
            border: 0;

            &.is-active {
                border-top: 0;
                border-bottom: rem(5) solid $color-secondary;
            }

            &:last-of-type {
                border-right: 0;
            }
        }
    }

    .performances-content {
        &-calendar {
            display: none;
    
            @media (--from-normal-screen-w) {
                display: block;
            }
        }

        &-menu {
            display: block;
    
            @media (--from-normal-screen-w) {
                display: none;
            }
        }
    }
}