/*
    Callout
*/
.callout {
    width: 100%;
    max-width: rem(300);
    padding-top: rem(20);
    margin-bottom: rem(30);

    font-size: rem(16);

    border-top: 1px solid $color-quaternary-shade-1;

    p {
        margin-bottom: 0;

        em {
            font-size: rem(14);
        }
    }
}
