/*
    Notification
*/
.notification {
    min-height: rem(60);
    color: $color-tertiary;

    .inner {
        position: relative;
        padding: rem(10);

        @media (--from-small-screen-w) {
            padding: rem(5) rem(80);
        }

        p {
            margin: 0;
            font-size: rem(16)
        }

        p.title {
            padding-right: rem(50);

            @media (--from-small-screen-w) {
                padding-right: 0;
            }
        }

        .description {
            color: $color-tertiary;
        }

        a.description {
            text-decoration: underline;
        }
    }

    &-icon {
        position: absolute;
        top: rem(10);
        left: rem(15);
    
        width: rem(28);
        height: rem(28);
    
        @media(--from-small-screen-w) {
            top: rem(20);
            left: rem(36);
        }
    }
    
    &-toggle-button {
        position: absolute;
        top: rem(10);
        right: rem(20);
        border: none;
        background: transparent;
        color: white;


        @media(--from-small-screen-w) {
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
