/*
    Review
*/
.review-carousel {
    .item {
        background-color: $color-quaternary-shade-1;
        
        &:focus {
            outline: none;
        }
    }

    .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: rem(360);
    }

    .stars {
        .icon {
            color: $color-maintenance;
            width: rem(30);
            height: rem(30);
        }
    }

    // React slick
    .slick-slider {
        ul.slick-dots {
            list-style: none;
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);

            li {
                margin-right: rem(5);

                &:last-child {
                    margin-right: 0;
                }

                .dot {
                    width: rem(30);
                    height: rem(5);
                    background-color: $color-quaternary-shade-2;
                    cursor: pointer;
                }

                &.slick-active .dot {
                    background-color: $color-secondary;
                }
            }
        }
    }
}