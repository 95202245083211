/*
    Date picker input
*/
.date-input-wrapper {
    position: relative;

    &:focus {
        outline: none;
    }

    input.date-picker-input {
        border: none;
        cursor: default;

        @media (--from-normal-screen-w) {
            width: rem(240);
        }

        &::placeholder {
            color: $color-danger;
        }
    }

    .date-picker-wrapper {
        position: absolute;
        top: calc(100% + rem(10));
        left: 50%;
        transform: translateX(-50%);
        width: auto;

        &:before {
            position: absolute;
            content: '';
            width: rem(20);
            height: rem(20);
            position: absolute;
            content: '';
            width: 1.25rem;
            height: 1.25rem;
            background-color: $color-tertiary;
            top: rem(-10);
            left: 50%;
            transform: rotate(45deg)
        }

        button {
            display: block;
            margin: auto;
        }
    }
}

.date-picker-wrapper {
    background-color: $color-tertiary;
    border-radius: rem(5);
    border: rem(1) solid $color-quaternary-shade-1;
    padding: rem(20) rem(10);

    .date-ranges {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        @media (--from-normal-screen-w) {
            padding: 0 rem(80);
            margin-bottom: rem(20);
        }

        button.button {
            border-color: $color-quaternary-shade-1;
            background-color: $color-quaternary-shade-1;
            text-transform: lowercase;
            flex-basis: 45%;
            margin: rem(5);

            @media (--from-normal-screen-w) {
                flex-basis: 22%;
            }

            &:active {
                color: $color-secondary;
            }
        }
    }

    button.button {
        display: flex;
        margin: auto;
    }

    // Customize day picker styles
    .DayPicker {
        width: 100%;

        @media (--from-normal-screen-w) {
            width: max-content;
        }

        &-Caption {
            font-family: $font-brandon-bold;
            font-size: rem(18);
            text-align: center;
        }

        // Nav button
        &-NavButton {
            &--prev {
                left: 0;
            }

            &--next {
                right: 0;
            }
        }

        &-Day {
            font-family: $font-primary;
            font-size: rem(16);
            width: rem(50);
            height: rem(50);
            border-radius: 0;

            &--today {
                color: $color-tertiary;
                border: rem(1) solid $color-secondary;
                background-color: $color-secondary;
            }

            &--today:not(&--disabled):not(&--start):not(&--end):not(&--outside):hover {
                background-color: $color-secondary;
            }

            &--from {
                color: $color-tertiary;
                background-color: $color-primary;
            }

            &--selected:not(&--disabled):not(&--start):not(&--end):not(&--outside) {
                color: $color-primary;
                border: rem(1) solid $color-primary;
                background-color: $color-tertiary;
            }

            &--start:not(&--disabled):not(&--outside),
            &--end:not(&--disabled):not(&--outside) {
                color: $color-tertiary;
                border: rem(1) solid $color-primary;
                background-color: $color-primary;
            }

            &--outside {
                background-color: $color-tertiary;
            }
        }
    }
}
