/*
    Tile with new design
*/
.tile-horizontal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    background-color: $color-tertiary;
    border: 1px solid $color-quaternary-shade-1;
    box-shadow: 1px 1px 4px 0 $color-quaternary-shade-3-020;
    color: $color-secondary;
    transition: all 0.1s ease-in;
    width: 100%;
    margin-bottom: rem(10);

    @media (--from-medium-screen-w) {
        width: calc(50% - rem(10));
        margin-bottom: rem(20);
    }

    &:hover {
        text-decoration: none;
        box-shadow: 1px 1px 4px 4px $color-quaternary-shade-3-020;

        .content-wrapper .title {
            color: $color-primary;
        }

        .thumbnail {
            transform: scale(1.02);
        }

        .thumbnail-wrapper.is-news {
            &::before {
                opacity: 1;
            }

            .button {
                visibility: visible;
            }
        }
    }

    & > * {
        width: 50%;
    }

    // Thumbnail
    .thumbnail-wrapper {
        overflow: hidden;
    }

    .thumbnail {
        width: 100%;
        min-height: 100%;
        object-fit: cover;
        transition: transform 0.1s ease-in;

        &.desktop {
            display: none;

            @media (--from-medium-screen-w) {
                display: block;
            }
        }

        &.mobile {
            display: block;

            @media (--from-medium-screen-w) {
                display: none;
            }
        }
    }

    // Types of thumbnail
    .thumbnail-wrapper.is-news {
        position: relative;

        &::before {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 100%;
            background-color: $color-secondary-070;
            opacity: 0;
            z-index: 1;
            transition: all 0.1s ease-in;
        }

        .button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            visibility: hidden;
            transition: all 0.1s ease-in;
            z-index: 1;
            color: $color-tertiary;
            width: fit-content;
            max-width: 100%;

            &:hover {
                color: $color-tertiary;
                border-color: $color-tertiary;
                background-color: transparent;
            }
        }

        .thumbnail {
            &.desktop {
                display: none;
    
                @media (--from-large-screen-w) {
                    display: block;
                }
            }
    
            &.mobile {
                display: block;
    
                @media (--from-large-screen-w) {
                    display: none;
                }
            }
        }
    }

    // Content
    .content-wrapper {
        padding: rem(10) rem(10) rem(20);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (--from-medium-screen-w) {
            padding: rem(20);
        }

        .title {
            margin-top: 0;
            transition: all 0.1s ease-in;
            color: $color-secondary;
            text-transform: none;
            margin-bottom: 0;
            margin-bottom: rem(5);

            @media (--from-small-screen-w) {
                margin-bottom: rem(10);
            }
        }

        .title,
        .description {
            display: -webkit-box;
            max-width: 100%;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        p {
            font-size: rem(14);
            margin-bottom: 0;
        }
    }

    // Types of content
    .show-content {
        height: 100%;

        @media (--from-medium-screen-w) {
            padding-bottom: rem(30);
        }

        .date {
            margin-bottom: rem(5);
        }

        .genre {
            position: absolute;
            bottom: rem(10);
        }

        .price {
            background: url($folder-icon/src/tickets.svg) left center no-repeat ;
        }

        .location {
            background: url($folder-icon/src/location-black.svg) left center no-repeat ;

        }


        .price,
        .location {
            padding-left: rem(20);
            background-size: rem(15);

            @media (--from-small-screen-w) {
                background-size: rem(20);
                margin-bottom: rem(5);
                padding-left: rem(30);
            }
        }

        .date,
        .price,
        .location {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .news-content {
        height: 100%;

        .date {
            margin-bottom: rem(10);
        }
    }

    // Hero tile
    &.is-hero {
        flex-direction: column;
        width: 100%;
        margin-bottom: rem(10);

        @media (--from-medium-screen-w) {
            flex-direction: row;
        }

        & > * {
            width: 100%;

            @media (--from-medium-screen-w) {
                width: 50%;
            }
        }

        .content-wrapper {
            @media (--from-large-screen-w) {
                padding: rem(45);
            }
        }

        .thumbnail-wrapper {
            &.is-news::before {
                content: '';
                display: none;
            }

            .thumbnail {
                &.desktop {
                    display: block;
                }
        
                &.mobile {
                    display: none;
                }
            }
        }

        button {
            margin-top: rem(20);
        }

        .news-content {
            height: fit-content;

            .title {
                margin-bottom: rem(15);
                transition: all 0.1s ease-in;
    
                @media (--from-medium-screen-w) {
                    margin-bottom: rem(25);
                }
            }
        }
    }
}