/*
    File download
*/
.file-download-block {
    .file-attachment {
        width: rem(600);
        max-width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: rem(20) rem(20) rem(20) rem(80);
        border: rem(1) solid $color-primary;
        background: url($folder-icon/src/file-download.svg) rem(18) no-repeat;
        background-size: rem(38) auto;
    }

    .information p {
        margin-bottom: 0;
    }

    .filename {
        font-size: rem(20);
    }

    .filesize {
        font-size: rem(14);
    }
}