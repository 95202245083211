/*
    Text content
*/
.text-content-block {
    display: flex;
    flex-direction: column;

    @media (--from-medium-screen-w) {
        flex-direction: row;
        align-items: flex-start;
    }

    .content-primary {
        flex-basis: calc(100% - 330px);

        @media (--from-small-screen-w) {
            margin-right: rem(50);
        }
    }

    .content-aside {
        padding-top: rem(20);

        @media (--from-medium-screen-w) {
            flex-basis: rem(350);
        }

        blockquote {
            margin: 0;
            font-family: $font-brandon-bold-italic;
            font-size: rem(20);
            line-height: 1.2;
            text-align: center;
            padding: rem(20) 0;

            @media (--from-small-screen-w) {
                font-size: rem(28);
            }
    
            @media (--from-medium-screen-w) {
                font-size: rem(34);
            }
        }

        &.no-top-padding {
            padding-top: 0;
        }

        &.bordered {
            border: rem(3) solid $color-quaternary-shade-2;

            @media (--from-medium-screen-w) {
                border: none;
            }
        }
    }
}