/*
    Custom Dropdown
*/
.custom-dropdown {
    position: relative;
    
    display: inline-block;

    padding-right: rem(20);

    color: $color-primary;

    @media (--from-medium-screen-w) {
        padding-right: rem(30);
    }

    &-label {
        margin-bottom: 0;
    }


    // Elements
    .icon {
        position: absolute;
        top: 20%;
        right: 0;
        width: rem(12);
        height: rem(12);

        @media (--from-medium-screen-w) {
            width: rem(20);
            height: rem(20);
        }
    }

    &-select {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: block;
        width: 100%;
        height: 100%;

        font-size: inherit;
        
        opacity: 0;
    }
}
