/*
    Performance info
*/
.performance-info {
    display: flex;
    flex-wrap: wrap;
    border: rem(1) solid $color-quaternary-shade-1;
    margin: rem(20) 0 rem(10);
    position: relative;
    background-color: $color-tertiary;

    @media (--from-medium-screen-w) {
        flex-wrap: nowrap;
    }

    .date,
    .details,
    .info {
        padding: rem(15);
        border-right: rem(1) solid $color-quaternary-shade-1;
    }

    .date {
        width: rem(100);

        span {
            display: block;
            font-family: $font-brandon-bold;
            font-size: rem(40);
            color: $color-primary;
            line-height: rem(40);
            text-align: center;
        }

        span.month {
            font-size: rem(18);
            line-height: rem(18);
            text-transform: uppercase;
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        font-size: rem(20);
        flex: 1;

        @media (--from-medium-screen-w) {
            flex: 0 1 rem(230);
        }

        .time {
            font-family: $font-brandon-bold;
        }
    }

    .info {
        flex-basis: 100%;
        font-size: rem(16);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-top: rem(1) solid $color-quaternary-shade-1;

        @media (--from-medium-screen-w) {
            flex: 1;
            font-size: rem(24);
            border-top: none;
        }
    }

    .buy {
        flex-basis: 100%;

        @media (--from-medium-screen-w) {
            flex-basis: auto;
        }

        & > .button {
            font-size: rem(14);
            width: 100%;
            border-radius: 0;
    
            @media (--from-medium-screen-w) {
                height: 100%;
                font-size: rem(20);
                width: rem(160);
            }

            &.with-label {
                position: relative;
                padding-top: rem(40);

                @media (--from-medium-screen-w) {
                    padding-top: rem(30);
                }

                small.label {
                    font-family: $font-brandon-medium;
                    font-size: rem(12);
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    padding: rem(5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $color-primary;
                    background-color: $color-tertiary;
                    border: rem(1) solid $color-primary;

                    .icon {
                        width: rem(20);
                        height: rem(20);
                    }
                }
            }
        }
    }

    &.not-available::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-tertiary;
        opacity: 0.5;
    }
}